<template>
    <custom-table
        :title="$t('pages.ecommerce.discountCoupon.creator.title')"
        :subTitle="$t('pages.ecommerce.discountCoupon.creator.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:coupon="{ row: record }">
            <router-link :to="'/ecommerce/discount-coupon?id=' + record.coupon.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ record.coupon.code }}</span>
            </router-link>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_discount_coupon_creator" ref="discountCouponCreator" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="discountCouponCreatorForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.creator.cols.coupon') }}</label>
                                <el-form-item prop="coupon_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.coupon_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable :disabled="form.updateStatus">
                                        <el-option v-for="(coupon, couponIndex) in discountCoupons" :key="couponIndex" :value="coupon.id" :label="coupon.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.creator.cols.product') }}</label>
                                <el-form-item prop="products" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.products" class="w-100" :placeholder="$t('common.chooseSelect')" filterable multiple>
                                        <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.creator.cols.options.beforeOrderDate') }}</label>
                                <el-form-item prop="options.before_order_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.options.before_order_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledBeforeOrderDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.creator.cols.options.afterOrderDate') }}</label>
                                <el-form-item prop="options.after_order_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.options.after_order_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledAfterOrderDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.discountCoupon.creator.cols.coupon"),
                    scopedSlots: {customRender: "coupon"},
                    key: "coupon"
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "created_at"},
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    options: {}
                }
            },
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.discountCoupon.creator.table;
        },
        discountCoupons() {
            return this.$store.state.ecommerce.discountCoupon.table.data;
        },
        products() {
            let products = this.$store.state.ecommerce.productManagement.product.indexRaw.table.data.map((product) => {
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id')

                return product;
            })

            return products;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.discountCouponManagement.creator"), [this.$t("menu.ecommerceManagement"), this.$t("menu.discountCouponManagement.title")]);

        this.$store.dispatch('ecommerce/discountCoupon/creator/get', {
            page: {},
            filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                sort: 'created_at:desc',
            })
        });

        this.$store.dispatch('ecommerce/productManagement/product/indexRaw/get', {
            page: {pageSize: 9999}
        });

        this.$store.dispatch('ecommerce/discountCoupon/get', {
            page: {pageSize: 9999}
        });
    },
    methods: {
        newRecord() {
            this.form.updateStatus = false;
            this.form.data = {
                options: {},
                active: true
            };
            this.form.title = this.$t("pages.ecommerce.discountCoupon.creator.new");

            this.showModal(this.$refs.discountCouponCreator);
        },
        fetchRecord(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.discountCoupon.creator.edit");
            this.axios.get(this.endpoints['ecommerce_discount_coupon_creator'] + '/' + record.id).then(response => {
                let data = response.data.data;

                data.products = data.products.map((product) => product.id);

                if (!data.options || Array.isArray(data.options)) {
                    data.options = {};
                }

                this.form.data = data;

                this.showModal(this.$refs.discountCouponCreator);
            });
        },
        onSubmit() {
            this.$refs.discountCouponCreatorForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareFormData();
                    this.form.loading = true;

                    if (formData.id) {
                        this.axios.put(this.endpoints['ecommerce_discount_coupon_creator'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.discountCouponCreator);
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    } else {
                        this.axios.post(this.endpoints['ecommerce_discount_coupon_creator'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.discountCouponCreator);
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = this.cloneData(this.form.data);

            return formData
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/discountCoupon/creator/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/discountCoupon/creator/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/discountCoupon/creator/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'created_at:desc',
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        disabledBeforeOrderDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.options.after_order_date ? this.$moment(this.form.data.options.after_order_date) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledAfterOrderDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.options.before_order_date ? this.$moment(this.form.data.options.before_order_date) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
    }
}
</script>

<style>
</style>